// AppRouter.js
import React, { useContext } from "react";
import Navbar from "./components/Navbar";
import Home from "./pages/Home";
import EmailAi from "./pages/EmailAi";
import Login from "./pages/Login"; // Import the Login component
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useNavigate,
} from "react-router-dom";
import { AuthContext } from "./context/AuthContext";
import { ThemeProvider } from "@mui/material/styles";
import theme from "./theme";
import PriceList from "./pages/PricesList";
import EmailList from "./pages/EmailList";

export const routes = [
  { path: "/", name: "Home", component: <Home /> },
  {
    path: "/prices_list",
    name: "Lista de Preços AI",
    component: <PriceList />,
  },
  { path: "/email_ai", name: "Email AI", component: <EmailAi /> },
  { path: "/email_list", name: "Emails", component: <EmailList /> },
  { path: "/login", name: "Login", component: <Login /> },
];

const AppRouter = () => {
  const { isSignedIn, loadingAuth } = useContext(AuthContext);
  const navigate = useNavigate();

  if (loadingAuth) {
    return <div>Loading...</div>;
  }

  return (
    <ThemeProvider theme={theme}>
      {isSignedIn ? (
        <>
          <Navbar />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/prices_list" element={<PriceList />} />
            <Route path="/email_ai" element={<EmailAi />} />
            <Route path="/email_list" element={<EmailList />} />
            {/* Outras rotas autenticadas */}
          </Routes>
        </>
      ) : (
        <Routes>
          <Route path="*" element={<Login />} />
          <Route path="/login" element={<Login />} />
          {/* Outras rotas não autenticadas */}
        </Routes>
      )}
    </ThemeProvider>
  );
};

export default AppRouter;
