import React, { useState } from "react";
import { TextField, Box } from "@mui/material";

const CopilotStyleAutocomplete = ({ priceData }) => {
  const [inputValue, setInputValue] = useState("");
  const [suggestion, setSuggestion] = useState("");
  const [filteredSuggestions, setFilteredSuggestions] = useState([]);
  const [selectedIndex, setSelectedIndex] = useState(0);

  const handleInputChange = (event) => {
    const value = event.target.value;
    setInputValue(value);

    // Get the last line of the input
    const lines = value.split("\n");
    const currentLine = lines[lines.length - 1].trim();

    // Match events and filter suggestions based on input
    const matches = priceData.filter((item) =>
      item.event.toLowerCase().includes(currentLine.toLowerCase())
    );

    if (matches.length > 0 && currentLine) {
      const commonPrefix = matches[0].event.substring(currentLine.length);
      setSuggestion(commonPrefix);
      setFilteredSuggestions(matches); // Filtered suggestions
      setSelectedIndex(0); // Reset index
    } else {
      setSuggestion("");
      setFilteredSuggestions([]);
    }
  };

  const handleKeyDown = (event) => {
    const lines = inputValue.split("\n");
    const currentLine = lines[lines.length - 1].trim();

    if (event.key === "Tab" && suggestion) {
      event.preventDefault();

      // Accept the main suggestion and display filtered suggestions
      lines[lines.length - 1] += suggestion;
      setInputValue(lines.join("\n"));
      setSuggestion("");

      const matches = priceData.filter(
        (item) =>
          item.event.toLowerCase() === lines[lines.length - 1].toLowerCase()
      );

      if (matches.length > 0) {
        setFilteredSuggestions(matches);
        setSelectedIndex(0);
      }
    }

    if (filteredSuggestions.length > 0) {
      if (event.key === "ArrowDown") {
        event.preventDefault();
        setSelectedIndex((prevIndex) =>
          prevIndex < filteredSuggestions.length - 1 ? prevIndex + 1 : 0
        );
      }

      if (event.key === "ArrowUp") {
        event.preventDefault();
        setSelectedIndex((prevIndex) =>
          prevIndex > 0 ? prevIndex - 1 : filteredSuggestions.length - 1
        );
      }

      if (event.key === "Enter") {
        event.preventDefault();

        // Accept the selected detailed suggestion
        const selectedSuggestion = filteredSuggestions[selectedIndex];
        const lines = inputValue.split("\n");
        lines[
          lines.length - 1
        ] = `${selectedSuggestion.event} (${selectedSuggestion.pax}, ${selectedSuggestion.price})`;
        setInputValue(lines.join("\n"));

        setFilteredSuggestions([]); // Clear filtered suggestions
        setSelectedIndex(0); // Reset index
      }
    }
  };

  const handleSuggestionClick = (index) => {
    const selectedSuggestion = filteredSuggestions[index];
    const lines = inputValue.split("\n");
    lines[
      lines.length - 1
    ] = `${selectedSuggestion.event} (${selectedSuggestion.pax}, ${selectedSuggestion.price})`;
    setInputValue(lines.join("\n"));

    setFilteredSuggestions([]); // Clear filtered suggestions
    setSelectedIndex(0); // Reset index
  };

  return (
    <Box sx={{ paddingBottom: 4, margin: "0 auto" }}>
      <Box
        sx={{ position: "relative", display: "inline-block", width: "100%" }}
      >
        <TextField
          label="Write your email"
          variant="outlined"
          fullWidth
          multiline
          rows={4}
          value={inputValue}
          onChange={handleInputChange}
          onKeyDown={handleKeyDown}
          placeholder="Start typing..."
        />
        {suggestion && (
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "12px",
              transform: "translateY(-50%)",
              color: "rgba(0, 0, 0, 0.4)",
              pointerEvents: "none",
              fontFamily: "monospace",
              fontSize: "16px",
              paddingLeft: "2px",
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
            }}
          >
            {suggestion}
          </Box>
        )}
        {filteredSuggestions.length > 0 && (
          <Box
            sx={{
              position: "absolute",
              top: "calc(100% + 10px)",
              left: "12px",
              color: "rgba(0, 0, 0, 0.6)",
              background: "#fff",
              boxShadow: "0px 4px 8px rgba(0,0,0,0.1)",
              borderRadius: "4px",
              zIndex: 10,
              padding: "8px",
              width: "calc(100% - 24px)",
              maxHeight: "200px", // Limit height of suggestions
              overflowY: "auto", // Add scroll if content exceeds height
            }}
          >
            {filteredSuggestions.map((item, index) => (
              <Box
                key={index}
                sx={{
                  padding: "4px 8px",
                  backgroundColor:
                    index === selectedIndex ? "#f0f0f0" : "transparent",
                  cursor: "pointer",
                }}
                onClick={() => handleSuggestionClick(index)}
              >
                {item.event} ({item.pax}, {item.price})
              </Box>
            ))}
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default CopilotStyleAutocomplete;
