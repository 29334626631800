import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#F37920", // Customize primary color
    },
    secondary: {
      main: "#005AA9", // Customize secondary color
    },
  },

  typography: {
    // Customize typography
    fontFamily: "Roboto, sans-serif",
    h1: {
      fontSize: "2.5rem",
      fontWeight: 500,
    },
    // Add more typography customizations as needed
  },
  // Add more customizations to the theme as needed
});

export default theme;
