import React from "react";
import { NavLink } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import { routes } from "../AppRouter";

const Navbar = () => {
  const theme = useTheme(); // Use o tema do MUI

  const styles = {
    sidebar: {
      position: "fixed",
      left: 0,
      top: 0,
      width: "250px",
      height: "100vh",
      backgroundColor: theme.palette.background.default,
      boxShadow: "2px 0 5px rgba(0, 0, 0, 0.1)",
      padding: "20px",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
    },
    link: {
      color: theme.palette.text.primary,
      textDecoration: "none",
      fontSize: "16px",
      padding: "10px 15px",
      borderRadius: "8px",
      marginBottom: "10px",
      transition: "background-color 0.3s ease",
    },
    activeLink: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
      fontWeight: "bold",
    },
    list: {
      listStyle: "none",
      padding: 0,
      margin: 0,
      display: "flex",
      flexDirection: "column",
    },
    footer: {
      marginTop: "auto",
      fontSize: "14px",
      color: theme.palette.text.secondary,
    },
  };

  return (
    <nav style={styles.sidebar}>
      <ul style={styles.list}>
        {routes.map(
          (route, index) =>
            route.name !== "Login" && (
              <li key={index}>
                <NavLink
                  style={styles.link}
                  to={route.path}
                  exact
                  activeStyle={styles.activeLink}
                >
                  {route.name}
                </NavLink>
              </li>
            )
        )}
      </ul>
    </nav>
  );
};

export default Navbar;
