const footer_PT = `
Adicionar o texto abaixo no final do email gerado:

Somente cotação, sem bloqueio e/ou reserva. 
Tarifas e disponibilidade sujeitas a alterações sem aviso prévio.
Políticas de pagamento: cobrança de 100% após confirmação do serviço. A fatura deve ser paga por transferência bancária.
As cobranças por cartão de crédito estão sujeitas a uma taxa administrativa de 5,2%.`;

const footer_EN= `
Add the text below at the end of the generated email:

Quotation only, without any booking or reservation.
Rates and availability are subject to change without notice.
Payment policies: 100% charge upon service confirmation. The invoice must be paid via bank transfer.
Credit card charges are subject to a 5.2% administration fee.`;


export const LASVEGAS_EN = `

#PRICESLIST#

Below is the email received:

EMAIL:
#EMAIL#
END OF EMAIL

[Notes: (do not add this note to the generated email, it is just to give you more information)
What I need:
(The email you create should be entirely in English, and the information and details should be translated into English).
- The email should be automatically generated based on the content of the email received.
- The email should be generated based on the content of the email received.

Considerations to generate the automatic response:

Service List Verification
- Check if the requested service is explicitly listed in the services list.
- If the service is explicitly, show the full list of prices and titles for each item on the list.
- Do not adapt any prices or titles.
- All options and titles of each requested item (Tour, Transfer Shopping, Transfer Shopping, Type of cars) regardless of the number of passengers. Show the complete price list for the items.
- If the email content mentions the number of passengers, please ignore that information and show the list of available vehicle types.
- Do not use the number of passengers to determine the price to show.
- The response will be based solely on the price list and service titles.
- I would like to display in the automatic email the description of each service found according to the price list.]


#APPENDIXUSER#

${footer_EN}
`;

export const LASVEGAS_PT = `

#PRICESLIST#

Abaixo email :

EMAIL recebido do cliente e que deve ser respondido:

#EMAIL#

FIM DO EMAIL

[Observações: (não adicionar a observação no email gerado, é apenas para dar mais informações para você)
O que preciso:
(O email que você criar deve ser totalmente em português, e as informações e detalhes informados devem ser traduzidos para português).
- O email deve ser gerado automaticamente com base no conteúdo do email recebido.
- O email deve ser gerado com base no conteúdo do2 email recebido.

Observações para serem levadas em consideração para gerar a resposta automática:

Verificação da Lista de Serviços
- Verificar se o serviço solicitado está explicitamente na lista de serviços.
- Se o serviço estiver explicitamente, mostrar a relação dos preços e títulos para o item da lista.
- Não faça nenhuma adaptação aos preços ou títulos.
- Todas as opções e os títulos de cada item SOLICITADO (Tour, Transfer Shopping, Transfer Shopping, Tipo de carros) independente do número de passageiros. Mostrar a relação de preços completa para os itens.
- Se o conteúdo do e-mail tiver o número de passageiros, por favor ignore essa informação e mostre a relação dos tipos de veículos disponíveis.
- A resposta se baseará exclusivamente na relação de preços e títulos da lista de serviços.
- Gostaria de mostrar no email automático a descrição de cada serviço encontrado conforme a lista de preços.]


#APPENDIXUSER#

${footer_PT}
`;

 
export const CALENDARIO = `'
eu vou mandar um exemplo de um arquivo

EXEMPLO JSON
  {
      "id": 1,
      "title": "",
      "start": year-month-dayThours:minutes:seconds,
      "end": year-month-dayThours:minutes:seconds,
      "type": "",
      "assignedTo": ""
    },
    

FIM JSON


- Você pode montar um novo json para o seguinte senario, o modelo gerado deve ser exatamente igual o modelo acima.
- Cada usuário deve ter uma cor (meeting, brainstorming, deadline, presentation, birthday or holiday) 
- Cada usuaria deve ter sua unica cor especifica e deve ser adicionado no type do json.
- Caso o nome do usuario seja repetido manter a mesma cor "type" para o mesmo nome, caso o nome seja diferente mudar a cor entre as opções de cores 
- O arquivo gerado de ter as opções id: number, title: string, start: year-month-dayThours:minutes:seconds, end: year-month-dayThours:minutes:seconds, type: string,assignedTo: string.
- opções de cores: meeting, brainstorming,deadline, presentation,birthday, or holiday)
- na resposta os valores tem que ser desta forma "id":,"title":,"start":,"end":,"type":"assignedTo":.

a data tem que ser formatada desta forma
"year" represents the full year (e.g., 2024).
"month" represents the month (e.g., 03 for March).
"day" represents the day of the month (e.g., 19).
"T" separates the date from the time.
"hours" represents the hour in 24-hour format (e.g., 13 for 1 PM).
"minutes" represents the minutes.
"seconds" represents the seconds.

#TEXTINPUT#
'`;