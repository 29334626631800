import React, { useState } from "react";
import { TextField, Box } from "@mui/material";

// Helper function to find common prefix
const findCommonPrefix = (items) => {
  if (items.length === 0) return "";
  let prefix = items[0];
  for (const item of items) {
    while (!item.startsWith(prefix)) {
      prefix = prefix.slice(0, -1);
      if (!prefix) break;
    }
  }
  return prefix;
};

const AutoCopilot = ({ priceData }) => {
  const [inputValue, setInputValue] = useState("");
  const [suggestion, setSuggestion] = useState("");
  const [detailedSuggestions, setDetailedSuggestions] = useState([]);
  const [selectedIndex, setSelectedIndex] = useState(0);

  const handleInputChange = (event) => {
    const value = event.target.value;
    setInputValue(value);

    // Get the current line being typed
    const lines = value.split("\n");
    const currentLine = lines[lines.length - 1].trim();

    // Find matches for the current input
    const matches = priceData.filter((item) =>
      item.event.toLowerCase().startsWith(currentLine.toLowerCase())
    );

    if (matches.length > 0) {
      // Find the common prefix among matched events
      const commonPrefix = findCommonPrefix(
        matches.map((match) => match.event)
      );
      setSuggestion(commonPrefix.substring(currentLine.length));

      // If all matches share the same event but differ in pax/price, show detailed suggestions
      const uniqueEvents = [...new Set(matches.map((item) => item.event))];
      if (uniqueEvents.length === 1) {
        setDetailedSuggestions(matches);
      } else {
        setDetailedSuggestions([]);
      }
    } else {
      setSuggestion("");
      setDetailedSuggestions([]);
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Tab" && suggestion) {
      event.preventDefault();

      // Accept the main suggestion and check for detailed suggestions
      const lines = inputValue.split("\n");
      const currentLine = lines[lines.length - 1].trim();
      const newLine = `${currentLine}${suggestion}`;
      lines[lines.length - 1] = newLine;
      setInputValue(lines.join("\n"));
      setSuggestion("");

      const matches = priceData.filter(
        (item) => item.event.toLowerCase() === newLine.toLowerCase()
      );

      if (matches.length > 0) {
        setDetailedSuggestions(matches);
      }
    }

    if (detailedSuggestions.length > 0) {
      if (event.key === "ArrowDown") {
        event.preventDefault();
        setSelectedIndex((prevIndex) =>
          prevIndex < detailedSuggestions.length - 1 ? prevIndex + 1 : 0
        );
      }

      if (event.key === "ArrowUp") {
        event.preventDefault();
        setSelectedIndex((prevIndex) =>
          prevIndex > 0 ? prevIndex - 1 : detailedSuggestions.length - 1
        );
      }

      if (event.key === "Enter") {
        event.preventDefault();

        // Accept the selected detailed suggestion
        const selectedSuggestion = detailedSuggestions[selectedIndex];
        const lines = inputValue.split("\n");
        lines[
          lines.length - 1
        ] = `${selectedSuggestion.event} (${selectedSuggestion.pax}, ${selectedSuggestion.price})`;
        setInputValue(lines.join("\n"));
        setDetailedSuggestions([]);
        setSelectedIndex(0);
      }
    }
  };

  const handleSuggestionClick = (index) => {
    const selectedSuggestion = detailedSuggestions[index];
    const lines = inputValue.split("\n");
    lines[
      lines.length - 1
    ] = `${selectedSuggestion.event} (${selectedSuggestion.pax}, ${selectedSuggestion.price})`;
    setInputValue(lines.join("\n"));
    setDetailedSuggestions([]);
  };

  return (
    <Box sx={{ padding: 2, maxWidth: 800, margin: "0 auto" }}>
      <Box
        sx={{ position: "relative", display: "inline-block", width: "100%" }}
      >
        <TextField
          label="Type your event"
          variant="outlined"
          fullWidth
          multiline
          rows={4}
          value={inputValue}
          onChange={handleInputChange}
          onKeyDown={handleKeyDown}
          placeholder="Start typing an event..."
        />
        {suggestion && (
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "12px",
              transform: "translateY(-50%)",
              color: "rgba(0, 0, 0, 0.4)",
              pointerEvents: "none",
              fontFamily: "monospace",
              fontSize: "16px",
            }}
          >
            {inputValue}
            <span style={{ color: "rgba(0, 0, 0, 0.2)" }}>{suggestion}</span>
          </Box>
        )}
        {detailedSuggestions.length > 0 && (
          <Box
            sx={{
              position: "absolute",
              top: "calc(100% + 10px)",
              left: "0",
              background: "#fff",
              boxShadow: "0px 4px 8px rgba(0,0,0,0.1)",
              borderRadius: "4px",
              zIndex: 10,
              padding: "8px",
              width: "100%",
              maxHeight: "200px",
              overflowY: "auto",
            }}
          >
            {detailedSuggestions.map((item, index) => (
              <Box
                key={index}
                sx={{
                  padding: "4px 8px",
                  backgroundColor:
                    index === selectedIndex ? "#f0f0f0" : "transparent",
                  cursor: "pointer",
                }}
                onClick={() => handleSuggestionClick(index)}
              >
                {item.pax}, {item.price}
              </Box>
            ))}
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default AutoCopilot;
